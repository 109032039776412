.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-layout-header {
  padding: 0;
  background-color: #fff;
  line-height: 0px;
}

.ant-layout-content {
  background-color: #f5f5f8;
}

.ant-layout-sider {
  background-color: #f5f5f8;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img {

}

.footerRow {
  margin-left: 0px;
  margin: 0em auto;
  padding-left: 0px;
  max-width: calc(1410px - 4em);
}

.Menu {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Page {
  text-align: left;
  margin: 2em auto;
  margin-top: calc(35px + 2em);
  margin-bottom: calc(20px + 2em);
  max-width: calc(1000px - 4em);
  font-size: 18px;
  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.MainPage {
  text-align: left;
  margin: 0em auto;
  margin-top: calc(35px + 2em);
  margin-bottom: calc(20px + 2em);
  max-width: calc(1410px - 4em);
  font-size: 18px;
  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.footnote {
  font-size: 14px;
}

.nameTitle {
  text-align: left;
  font-size: 42px;
}

.sectionTitle {
  text-align: left;
  font-size: 32px;
}

.selfImage {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0px auto;
}

.researchImage {
  width: 50%;
  display: block;
  margin: 0px auto;
}

.researchSummaryImage {
  height: 25vh;
  display: block;
  margin: auto;
  @media (max-width: 500px) {
    height: 20vh;
  }
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
}

li.pubList {
  font-size: 1.25em;
}

li p {
  font-size: .8em;
}

li h4 {
  font-size: .8em;
}

h4 {
  font-size: 1em;
}

.selfAuthor {
  font-weight: bold;
}

.journalName {
  font-style: italic;
}

.italic {
  font-style: italic;
}

.suppInfo p {
  margin: 0px;
}

svg {
  margin: 0px !important;
  padding: 0px !important;
}

rect {
  margin: 0px !important;
  padding: 0px !important;
  fill: #012169;
}


.publicationTitle {
  text-align: left;
  margin-top: 28px;
  font-size: 24px;
}

.projectImage {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.ant-layout-footer {
  background-color: #e6e6e6;
  border-top: solid 1px #aaa;
  @media(max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ant-layout-header {
  height: 40px
}

footer p {
  margin-top: 16px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 14px;
}

footer span {
  margin-left: 12px;
  margin-right: 12px;
  @media(max-width: 500px) {
    margin-left: 1px;
    margin-right: 1px;
  }
  font-size: 16px;
}

p {
  color: #222;
  text-align: justify;
  // margin-right:10px;
  // margin-left:10px;
}
